import React from "react"
import Helmet from "react-helmet"

const Head = () => {
      
    return (
      <Helmet>
          
      </Helmet>
    ) 
}
  
  export default Head
  