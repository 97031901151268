import React from 'react'
import { useStaticQuery, Link, graphql } from "gatsby";

import Navbar from 'react-bootstrap/Navbar';

import { removePre } from './../util/common';
import CookieConsent from "react-cookie-consent";

const Footer = () => {
    const data = useStaticQuery(graphql`
        query {
            allWordpressAcfOptions {
                nodes {
                    options {
                        footer_heading
                            footer_logo {
                                source_url
                            }
                        footer_social_icon_links {
                            footer_social_icon_link
                            footer_social_icon_title
                        }
                        footer_copy_right_text
                    }
                }
            }
            allWordpressMenusMenusItems {
                nodes {
                    name
                    items {
                        title
                        url
                    }
                }
            }
        }
    `)

    const home = data.allWordpressMenusMenusItems.nodes[0].items[0];
    const aboutus = data.allWordpressMenusMenusItems.nodes[0].items[1];
    const services = data.allWordpressMenusMenusItems.nodes[0].items[2];
    const technology = data.allWordpressMenusMenusItems.nodes[0].items[3];
    const work = data.allWordpressMenusMenusItems.nodes[0].items[4];
    //const blog = data.allWordpressMenusMenusItems.nodes[0].items[5];
    const getintouch = data.allWordpressMenusMenusItems.nodes[0].items[6];

    const footerLast = data.allWordpressAcfOptions.nodes[0].options;
    const facebook = footerLast.footer_social_icon_links[0];
    const linkedin = footerLast.footer_social_icon_links[1];
    const twitter = footerLast.footer_social_icon_links[2];
    const insta = footerLast.footer_social_icon_links[3];
    
    return (
    <footer>
        <div className="footer-main ">
        <div className="footer-top ">
            <div className="container ">
                <div className="row ">
                    <div className="col-md-2 ">
                        <Link to="/" className="footer-logo"><img src={footerLast.footer_logo.source_url} alt="footer-logo "/></Link>                        
                    </div>
                    <div className="col-md-10 ">
                        <h2>{footerLast.footer_heading}</h2>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-middle ">
            <div className="container ">
                <div className="footer-middle-inner">
                    <div className="row ">
                        <div className="col-lg-9 col-md-12">
                        <Navbar>
                            <ul className="footer-menu ">
                                <li><Link to={`${removePre(home.url)}`} className="menu-nav__link nav-link">{home.title}</Link></li>
                                <li><Link to={`${removePre(aboutus.url)}`} className="menu-nav__link nav-link">{aboutus.title}</Link></li>
                                <li><Link to={`${removePre(services.url)}`} className="menu-nav__link nav-link">{services.title}</Link></li>
                                <li><Link to={`${removePre(technology.url)}`} className="menu-nav__link nav-link">{technology.title}</Link></li>
                                <li><Link to={`${removePre(work.url)}`} className="menu-nav__link nav-link">{work.title}</Link></li>
{ /* <li><Link to={`${removePre(blog.url)}`} className="menu-nav__link nav-link">{blog.title}</Link></li> */ }
                                <li><Link to={`${removePre(getintouch.url)}`} className="menu-nav__link nav-link">{getintouch.title}</Link></li>
                            </ul>                            
                        </Navbar>
                        </div>
                        <div className="col-lg-3 col-md-12">
                            <ul className="social-icon ">                                
                                <li><a href={facebook.footer_social_icon_link} rel="noopener noreferrer" target="_blank">{facebook.footer_social_icon_title}</a></li>
                                <li><a href={linkedin.footer_social_icon_link} rel="noopener noreferrer" target="_blank">{linkedin.footer_social_icon_title}</a></li>
                                <li><a href={twitter.footer_social_icon_link} rel="noopener noreferrer" target="_blank">{twitter.footer_social_icon_title}</a></li>
                                <li><a href={insta.footer_social_icon_link} rel="noopener noreferrer" target="_blank">{insta.footer_social_icon_title}</a></li>
                                {/* <li><a href={be.footer_social_icon_link} rel="noopener noreferrer" target="_blank">{be.footer_social_icon_title}</a></li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-bottom ">
            <div className="container ">
                <div className="row ">
                    <div className="col-md-12 ">
                        <div className="copy-right " dangerouslySetInnerHTML = {{__html:footerLast.footer_copy_right_text}} />
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    <CookieConsent       
    hideOnAccept={true} 
    location="bottom"
    buttonText="Accept"
    cookieName="myAwesomeCookieName2"
    style={{ background: "#c7c8ca" }}
    buttonStyle={{ border: "solid white 1px", fontSize: "13px",background: "transparent", color: "white", margin: "20px 120px 15px 15px" }}    
    >
        This website uses cookies to improve service, for analytical and advertising purposes. Confirm your consent to the use of cookies.
    </CookieConsent>
    </footer>
    
    )
}
export default Footer
