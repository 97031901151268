/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Transition from "./transition"
import Head from "./head";
import Header from "./header";
import Footer from "./footer";
import "./layout.css"
import './../assets/scss/index.scss'

const Layout = ({ children, location }) => {
  const duration = 300
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Head />
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          margin: `0 auto`,
          //maxWidth: 960,
          //padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <Transition location={location}><main>{children}</main></Transition>
        {/* <main>{children}</main> */}
        
      </div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
