// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-get-in-touch-js": () => import("./../src/pages/get-in-touch.js" /* webpackChunkName: "component---src-pages-get-in-touch-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-templates-platforms-js": () => import("./../src/templates/platforms.js" /* webpackChunkName: "component---src-templates-platforms-js" */),
  "component---src-templates-services-js": () => import("./../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */),
  "component---src-templates-work-js": () => import("./../src/templates/work.js" /* webpackChunkName: "component---src-templates-work-js" */),
  "component---src-templates-workpost-js": () => import("./../src/templates/workpost.js" /* webpackChunkName: "component---src-templates-workpost-js" */)
}

