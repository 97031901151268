import PropTypes from "prop-types"
import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby";

import Navbar from 'react-bootstrap/Navbar';
import { removePre } from './../util/common';

const Header = () => {

    const data = useStaticQuery(graphql`
    {
        allWordpressMenusMenusItems {
            nodes {
                name
                items {
                title
                url
                }
            }
        }
        allWordpressAcfOptions {
            nodes {
              options {        
                header_logo {
                  source_url
                }
                header_light_logo {
                  source_url
                }
                header_right_link
                header_right_link_text
              }
            }
          }
      
    }
  `)
  
  const headdata = data.allWordpressAcfOptions.nodes[0].options;
  const home = data.allWordpressMenusMenusItems.nodes[0].items[0];
  const aboutus = data.allWordpressMenusMenusItems.nodes[0].items[1];
  const services = data.allWordpressMenusMenusItems.nodes[0].items[2];
  const technology = data.allWordpressMenusMenusItems.nodes[0].items[3];
  const work = data.allWordpressMenusMenusItems.nodes[0].items[4];
 // const blog = data.allWordpressMenusMenusItems.nodes[0].items[5];
  const getintouch = data.allWordpressMenusMenusItems.nodes[0].items[6];
  
  const openclosemenu = (el) => {  
    // const darklogo = document.getElementsByClassName('dark-logo');
    // const lightlogo = document.getElementsByClassName('light-logo');
   
    // setTimeout(function(){ darklogo.style.display = "none"; lightlogo.style.display = "block";  }, 5000);
    // console.log(darklogo);
    // console.log(lightlogo);
    const headm = document.getElementsByClassName('header-main')[0];   
    headm.classList.toggle('menu-open');
    const menubtn = document.getElementsByClassName('menu-btn')[0];
    menubtn.classList.toggle('menu-btn_active');
    const menunav = document.getElementsByClassName('menu-nav')[0];
    menunav.classList.toggle('menu-nav_active');    
  }
  const ocmobilemenu = (el) => {
    const hedmn = document.getElementsByClassName('header-main')[0];
    hedmn.classList.toggle('menu-open');
    const hmmenu = document.getElementsByClassName('mob-head-menu')[0];
    hmmenu.classList.toggle('mob-menu-open');
  }
  
  return(
  <header>
    <div className="header-main">
        <div className="container-fluid">
            <div className="row align-items-center">
                <div className="col-md-3 pl-0">
                  <Link to="/" className="dark-logo"><img src={headdata.header_logo.source_url} alt="dark-logo" /></Link>
                  {/* <Link to="/" className="light-logo"><img src={headdata.header_light_logo.source_url} alt="light-logo" /></Link>                    */}
                </div>
                <div className="col-md-8  pr-md-0">
                    <div className="get-in-touch ">
                      <Link to={`${removePre(getintouch.url)}`} className="main-menu-git">{getintouch.title}</Link>
                    </div>
                </div>
                <div className="col-md-1 ">
                    <div className="menu-block text-center ">

                        <Navbar className="menu-nav" id="desktopmenu">
                          {/* <Link to="/" className="light-logo"><img src={headdata.header_light_logo.source_url} alt="light-logo" /></Link>    */}
                          <div className="meni-inner">
                          <Link to={`${removePre(home.url)}`} className="menu-nav__link nav-link" onClick={openclosemenu} >{home.title}</Link>
                          <Link to={`${removePre(aboutus.url)}`} className="menu-nav__link nav-link" onClick={openclosemenu} >{aboutus.title}</Link>
                          <Link to={`${removePre(services.url)}`} className="menu-nav__link nav-link" onClick={openclosemenu} >{services.title}</Link>
                          <Link to={`${removePre(technology.url)}`} className="menu-nav__link nav-link" onClick={openclosemenu} >{technology.title}</Link>
                          <Link to={`${removePre(work.url)}`} className="menu-nav__link nav-link" onClick={openclosemenu} >{work.title}</Link>
                      {/* <Link to={`${removePre(blog.url)}`} className="menu-nav__link nav-link" onClick={openclosemenu} >{blog.title}</Link> */ }
                          <Link to={`${removePre(getintouch.url)}`} className="menu-nav__link nav-link" onClick={openclosemenu} >{getintouch.title}</Link>      
                          </div>                      
                        </Navbar>
                        <Navbar className="menu-nav mob-head-menu" id="mobilemenu">
                        <Link to={`${removePre(home.url)}`} className="menu-nav__link nav-link" onClick={openclosemenu} >{home.title}</Link>
                          <Link to={`${removePre(aboutus.url)}`} className="menu-nav__link nav-link" onClick={ocmobilemenu} >{aboutus.title}</Link>
                          <Link to={`${removePre(services.url)}`} className="menu-nav__link nav-link" onClick={ocmobilemenu} >{services.title}</Link>
                          <Link to={`${removePre(technology.url)}`} className="menu-nav__link nav-link" onClick={ocmobilemenu} >{technology.title}</Link>
                          <Link to={`${removePre(work.url)}`} className="menu-nav__link nav-link" onClick={ocmobilemenu} >{work.title}</Link>
                      {/* <Link to={`${removePre(blog.url)}`} className="menu-nav__link nav-link" onClick={ocmobilemenu} >{blog.title}</Link> */ }
                          <Link to={`${removePre(getintouch.url)}`} className="menu-nav__link nav-link" onClick={ocmobilemenu} >{getintouch.title}</Link>                            

                        </Navbar>
                        <button id="mainmenu" className="menu-btn " onClick={openclosemenu}>
                            <span className="line"></span>
                            <span className="line"></span>
                            <span className="line"></span>
                        </button>
                        <button id="mobmenu" className="menu-btn " onClick={ocmobilemenu}>
                            <span className="line"></span>
                            <span className="line"></span>
                            <span className="line"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
